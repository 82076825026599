import React from "react"
import LayoutA5 from "../../components/layout-a5"
import homeStyles from "./a5_home.module.css"
import FastEntryA5 from '../../components/fast-entry-a5'
import CountDownA5 from "../../components/countdown-a5"
import NewsA5 from '../../components/news-a5'
import WonderfulPhotosA5 from "../../components/wonderful-photos-a5"
import SupportersA5 from "../../components/supporters-a5"


export default  class A5Home extends React.Component {
  render() {
    return(
      <LayoutA5>
        <div className={homeStyles.poster}>
        </div>
        <div className={homeStyles.fastentry}>
          <FastEntryA5 />
        </div>
        <div className={homeStyles.countdown}>
          <CountDownA5 count={1000}/>
        </div>
        <div className={homeStyles.news}>
          <NewsA5 />
        </div>
        <div>
          <WonderfulPhotosA5 />
        </div>
        <div className={homeStyles.supporters}>
          <SupportersA5 />
        </div>
      </LayoutA5>
    )
  }
}