import React from "react"
import fastEntryStyles from './fast-entry-a5.module.css'


export default class FastEntryA5 extends React.Component{
  render() {
    return(
      <div className={fastEntryStyles.fastEntryContainer}>
        <div className={fastEntryStyles.leftBtn}>
          <div>报名</div>
        </div>
        <div className={fastEntryStyles.rightBtn}>
          <div>参赛号</div>
        </div>
      </div>
    )
  }
}