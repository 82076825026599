import newsStyles from './news-a5.module.css'
import React from "react"

export default class NewsA5 extends React.Component {
  render() {
    return (
      <div className={newsStyles.newsNotices}>
        <div className={newsStyles.newsContainer} >
          <div  className={newsStyles.newsTitle}>
            <div className={newsStyles.titleText}>新闻</div>
            <div className={newsStyles.titleShadow}></div>
          </div>
          <div className={newsStyles.newslist}>
            <div className={newsStyles.newsItem}>
              <div className={newsStyles.arrow}></div>
              <a>1、新闻一新闻一新闻一新闻一新闻一新闻一新闻一</a>
            </div>
            <div className={newsStyles.newsItem}>
              <div className={newsStyles.arrow}></div>
              <a>2、新闻二新闻二新闻二新闻二</a>
            </div>
            <div className={newsStyles.newsItem}>
              <div className={newsStyles.arrow}></div>
              <a>3、新闻三新闻三新闻三新闻三</a>
            </div>
          </div>
        </div>



        <div className={newsStyles.noticesContainer}>
          <div  className={newsStyles.noticeTitle}>
            <div className={newsStyles.titleText}>公告</div>
            <div className={newsStyles.titleShadow}></div>
          </div>
          <div className={newsStyles.noticeslist} >
            <div className={newsStyles.noticeItem}>
              <div className={newsStyles.arrow}></div>
              <a>1、公告一公告一公告一公告一</a>
            </div>
            <div className={newsStyles.noticeItem}>
              <div className={newsStyles.arrow}></div>
              <a>2、公告二公告二公告二公告二</a>
            </div>
            <div className={newsStyles.noticeItem}>
              <div className={newsStyles.arrow}></div>
              <a>3、公告二公告三公告三</a>
            </div>
          </div>
        </div>


      </div>

    )
  }
}